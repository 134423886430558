import { useState, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';

const useSanitizeHtml = (dirtyHtml, options = sanitizeHtml.defaults) => {
	const [html, setHtml] = useState('');

	useEffect(() => {
		setHtml(sanitizeHtml(dirtyHtml, options));
	}, [dirtyHtml, options]);

	return html;
};

export default useSanitizeHtml;
