import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Year from './footer/year';

const StyledFooter = styled.footer`
	display: grid;
	grid-template-columns: repeat(2, auto);
	grid-template-rows: 1fr;
	margin-top: 103px;
	line-height: 1;

	@media (max-width: 26.9375em) {
		font-size: 1rem;
		margin-top: 102px;
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: 1rem;
		margin-top: 117px;
	}

	@media (min-width: 30em) and (max-width: 63.9375em) {
		font-size: calc(21rem / 16);
		margin-top: 117px;
	}

	@media (min-width: 64em) {
		font-size: calc(27rem / 16);
		margin-top: 153px;
	}
`;

const Footer = (props) => {
	const { siteTitle } = props;
	return (
		<StyledFooter>
			{siteTitle}
			<Year />
		</StyledFooter>
	);
};

Footer.propTypes = {
	siteTitle: PropTypes.element,
};

export default Footer;
