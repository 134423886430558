import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeaderText = styled.p`
	white-space: pre-line;
	max-width: 1264px;

	@media (max-width: 47.9375em) {
		margin-top: calc(-1em + 1px);
		max-width: calc(303vw * 100 / 375);
	}

	@media (min-width: 48em) {
		margin-bottom: 1em;
	}

	@media (min-width: 48em) and (max-width: 63.9375em) {
		grid-column: 1 / 2;
		grid-row: 2 / 3;
	}

	@media (min-width: 64em) {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
	}

	@media (max-width: 26.9375em) {
		font-size: calc(18rem / 16);
		line-height: calc(20rem / 16);
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: calc(21rem / 16);
		line-height: calc(23rem / 16);
	}

	@media (min-width: 30em) and (max-width: 79.9375em) {
		font-size: calc(27rem / 16);
		line-height: calc(29rem / 16);
	}

	@media (min-width: 80em) {
		font-size: calc(36rem / 16);
		line-height: calc(38rem / 16);
	}
`;

const HeaderText = (props) => {
	const { children } = props;

	return <StyledHeaderText>{children}</StyledHeaderText>;
};

HeaderText.propTypes = {
	children: PropTypes.string,
};

export default HeaderText;
