import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import infoData from '../../settings/info.json';
import Contact from './info-contact';
import Credits from './info-credits';
import Copyright from './info-copyright';

const StyledInfo = styled.aside`
	background-color: var(--ts-menu-color);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 100;
	height: 100%;
	display: flex;
	flex-flow: column nowrap;

	@media (max-width: 29.9375em) {
		width: 100vw;
		padding: 0px 50px 0 31px;
	}

	@media (min-width: 30em) and (max-width: 89.9375em) {
		max-width: 500px;
		padding: 0px 49px 0px 40px;
	}

	@media (min-width: 90em) {
		max-width: 609px;
		padding: 0px 48px 0px 50px;
	}

	&.info-enter {
		transform: translateX(120%);
	}

	&.info-enter.info-enter-active {
		transform: translateX(0);
		transition: transform 500ms ease-in-out;
	}

	&.info-exit {
		transform: translateX(0);
	}

	&.info-exit.info-exit-active {
		transform: translateX(120%);
		transition: transform 500ms ease-in-out;
	}
`;

const Info = (props) => {
	const { showInfo, infoButton } = props;

	const componentRef = useRef();
	const clickOutsideRef = useRef();

	const handleClickOutside = showInfo;

	useEffect(() => {
		clickOutsideRef.current = handleClickOutside;
	});

	useEffect(() => {
		const isClient = typeof window === 'object';
		let unmounted = false;

		if (!isClient) {
			return false;
		}

		const clickOutside = (event) => {
			if (unmounted || componentRef.current.contains(event.target)) {
				return false;
			}
			handleClickOutside();
		};

		const click = window.addEventListener('mousedown', clickOutside);

		return () => {
			window.removeEventListener('mousedown', click);
			unmounted = true;
		};
	}, [handleClickOutside]);

	const { contact, credits, copyright } = infoData;

	return (
		<StyledInfo ref={componentRef}>
			{infoButton}
			<Contact contact={contact} />
			<Credits credits={credits} />
			<Copyright copyright={copyright} />
		</StyledInfo>
	);
};

Info.propTypes = {
	showInfo: PropTypes.func,
	infoButton: PropTypes.element,
};

export default Info;
