import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import '../fonts/fonts.css';

const GlobalStyles = createGlobalStyle`
	${normalize}

	:root {
		font-size: 100%;
		--ts-menu-color: hsl(37, 98%, 66%);
		--ts-blue: hsl(237, 96%, 52%);
	}

	html {
  		box-sizing: border-box;
	}

	body {
		background-color: white;
		font-family: 'favoritSTD', sans-serif;

		@media (min-width: 30em) {
			overflow-y: scroll;
		}
	}
	
	*, *:before, *:after {
		box-sizing: inherit;
	}

	h1, h2 {
		margin: 0;
	}

	h1, h2, h3, h4, h5, h6 {
		font-size: inherit;
		font-weight: initial;
	}

	p {
		margin: 0;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	a {
		color: black;
		text-decoration: none;
	}

	a:hover,
	a:focus {
		text-decoration: underline;
	}

	a:focus {
		outline: none; /* not my call */
	}

`;

export default GlobalStyles;
