import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeaderTitle = styled.div`
	line-height: 1;

	@media (min-width: 48em) {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	@media (max-width: 26.9375em) {
		margin-bottom: calc(10rem / 16);
	}

	@media (min-width: 27em) and (max-width: 63.9375em) {
		margin-bottom: calc(4rem / 16);
	}

	@media (max-width: 26.9375em) {
		font-size: calc(44rem / 16);
	}

	@media (min-width: 27em) and (max-width: 79.9375em) {
		font-size: calc(58rem / 16);
	}

	@media (min-width: 80em) {
		font-size: calc(68rem / 16);
	}
`;

const HeaderTitle = (props) => {
	const { children, home } = props;
	return (
		<StyledHeaderTitle>
			{home ? <h1>{children}</h1> : children}
		</StyledHeaderTitle>
	);
};

HeaderTitle.propTypes = {
	children: PropTypes.string,
	home: PropTypes.bool,
};

export default HeaderTitle;
