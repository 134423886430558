import React from 'react';
import styled from 'styled-components';

const StyledYear = styled.time`
	justify-self: end;
`;

const Year = () => {
	const year = new Date().getFullYear();
	return <StyledYear dateTime={year}>{year}.</StyledYear>;
};

export default Year;
