import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSiteTitle = styled.div`
	justify-self: start;
`;

const SiteTitle = (props) => {
	const { title } = props;
	return <StyledSiteTitle>©{title}</StyledSiteTitle>;
};

SiteTitle.propTypes = {
	title: PropTypes.string,
};

export default SiteTitle;
