import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import settingsData from '../settings/settings.json';
import HeaderText from './header/header-text';

const StyledHeader = styled.header`
	@media (max-width: 29.9375em) {
		margin-bottom: ${(props) =>
			props.home ? `calc(20rem / 16)` : `calc(114rem / 16)`};
	}

	@media (min-width: 30em) {
		margin-bottom: ${(props) =>
			props.home ? `calc(34rem / 16)` : `calc(215rem / 16)`};
	}

	@media (min-width: 48em) {
		display: grid;
		grid-gap: calc(25rem / 16);
	}

	@media (min-width: 48em) and (max-width: 63.9375em) {
		grid-template-columns: ${(props) =>
			props.home ? `auto 2.9rem` : `repeat(2, auto)`};
		grid-template-rows: auto 1fr;
	}

	@media (min-width: 64em) {
		display: grid;
		grid-template-columns: ${(props) =>
			props.home ? `auto 1fr 3.31rem` : `repeat(2, auto)`};
		grid-template-rows: 1fr;
	}
`;

const Header = (props) => {
	const { home, headerTitle, menu } = props;
	const { headerText } = settingsData;
	return (
		<StyledHeader home={home}>
			{headerTitle}
			{menu}
			{home && <HeaderText>{headerText}</HeaderText>}
		</StyledHeader>
	);
};

Header.propTypes = {
	home: PropTypes.bool,
	headerTitle: PropTypes.element,
	menu: PropTypes.element,
};

export default Header;
