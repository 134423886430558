import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import GlobalStyles from './global-styles';
import Header from './header';
import Footer from './footer';
import settingsData from '../settings/settings.json';
import Info from './info/info';
import Background from './layout/background';
import HeaderTitle from './header/header-title';
import Menu from './header/menu';
import MenuButton from './header/menu-button';
import InfoButton from './info/info-button';
import SiteTitle from './footer/site-title';

const handleClick = (info, showInfo) => {
	showInfo(!info);
};

const StyledLayout = styled.div`
	position: relative;
	min-height: 100vh;

	@media (max-width: 29.9375em) {
		padding: 13px 15px 12px 15px;
	}

	@media (min-width: 30em) and (max-width: 63.9375em) {
		padding: 19px 18px 15px 18px;
	}

	@media (min-width: 64em) {
		padding: 18px 28px 15px 28px;
	}
`;

export default function Layout(props) {
	const {
		children,
		location: { pathname },
	} = props;

	// Toggle 'info' component
	const [info, showInfo] = useState(false);

	// Check if we are on home page (before we set the state)
	const checkLocation = useCallback(() => {
		if (pathname === '/') {
			return true;
		}
		return false;
	}, [pathname]);

	// State piece where we store whether we are on the home page or not
	const [isHome, setIsHome] = useState(checkLocation());

	// Update isHome state piece every time location changes
	useEffect(() => {
		setIsHome(checkLocation());
	}, [checkLocation]);

	const { siteTitle } = settingsData;

	const menuButton = (
		<MenuButton showInfo={showInfo} info={info} handleClick={handleClick}>
			Contact
		</MenuButton>
	);

	const menu = <Menu home={isHome} menuButton={menuButton} />;
	const headerTitle = <HeaderTitle home={isHome}>{siteTitle}</HeaderTitle>;

	const infoButton = (
		<InfoButton handleClick={handleClick} showInfo={showInfo} info={info} />
	);

	const footerSiteTitle = <SiteTitle title={siteTitle} />;

	return (
		<StyledLayout>
			<GlobalStyles />
			<Background />
			<Header home={isHome} headerTitle={headerTitle} menu={menu} />
			<CSSTransition
				key="info"
				in={info}
				classNames="info"
				mountOnEnter
				unmountOnExit
				timeout={{ enter: 500, exit: 500 }}
			>
				<Info showInfo={showInfo} infoButton={infoButton} />
			</CSSTransition>
			{children}
			<Footer siteTitle={footerSiteTitle} />
		</StyledLayout>
	);
}

Layout.propTypes = {
	children: PropTypes.element,
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}),
};
