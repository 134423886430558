import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSanitizeHtml from '../../util/use-sanitize-html';
import { sanitizeDefaults } from '../../util/util';

const StyledCredits = styled.div`
	margin-bottom: 1em;

	@media (max-width: 29.9375em) {
		font-size: 1rem;
		line-height: calc(19 / 16);
	}

	@media (min-width: 30em) and (max-width: 89.9375em) {
		font-size: calc(18rem / 16);
		line-height: calc(21 / 18);
	}

	@media (min-width: 90em) {
		font-size: calc(23rem / 16);
		line-height: calc(26 / 23);
	}

	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const Credits = (props) => {
	const { credits } = props;
	const cleanCredits = useSanitizeHtml(credits, sanitizeDefaults);

	return <StyledCredits dangerouslySetInnerHTML={{ __html: cleanCredits }} />;
};

Credits.propTypes = {
	credits: PropTypes.string,
};

export default Credits;
