import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledMenu = styled.ul`
	@media (max-width: 47.9375em) {
		position: relative;
	}

	@media (min-width: 48em) {
		justify-self: end;
	}

	@media (min-width: 48em) and (max-width: 63.9375em) {
		grid-column: 2 / 3;
		grid-row: 1 / 3;
	}

	@media (min-width: 64em) {
		grid-column: 3 / 4;
		grid-row: 1 / 2;
	}

	@media (max-width: 26.9375em) {
		font-size: calc(25rem / 16);
	}

	@media (min-width: 27em) and (max-width: 29.9375em) {
		font-size: calc(28rem / 16);
	}

	@media (min-width: 30em) and (max-width: 79.9375em) {
		font-size: calc(36rem / 16);
	}

	@media (min-width: 80em) {
		font-size: calc(46rem / 16);
	}

	li {
		&,
		& a {
			display: inline-block;
		}

		@media (min-width: 48em) {
			& a {
				transform: translate(100%, 0);
			}
		}
	}
`;

const Menu = (props) => {
	const { home, menuButton } = props;

	return (
		<StyledMenu>
			{!home && (
				<li>
					<Link to="/">Index</Link>
				</li>
			)}
			<li>{menuButton}</li>
		</StyledMenu>
	);
};

Menu.propTypes = {
	home: PropTypes.bool,
	menuButton: PropTypes.element,
};

export default Menu;
