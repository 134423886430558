import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSanitizeHtml from '../../util/use-sanitize-html';
import { sanitizeDefaults } from '../../util/util';

const StyledContact = styled.div`
	font-size: var(--contact-font-size);
	line-height: var(--contact-line-height);
	margin-top: calc(
		2.8 * var(--contact-line-height) * var(--contact-font-size)
	);
	margin-bottom: auto;

	@media (max-width: 29.9375em) {
		--contact-font-size: 4.19vh;
		@supports (font-size: clamp(10px, 5vw, 10px)) {
			--contact-font-size: clamp(27px, 4.19vh, 29px);
		}
		--contact-line-height: calc(31 / 28);
	}

	@media (min-width: 30em) and (max-width: 89.9375em) {
		--contact-font-size: 4.95vh;
		@supports (font-size: clamp(10px, 5vw, 10px)) {
			--contact-font-size: clamp(37px, 4.95vh, 39px);
		}
		--contact-line-height: calc(41 / 38);
	}

	@media (min-width: 90em) {
		--contact-font-size: 4.64vh;
		@supports (font-size: clamp(10px, 5vw, 10px)) {
			--contact-font-size: clamp(47px, 4.64vh, 49px);
		}
		--contact-line-height: calc(51 / 48);
	}

	p {
		&:first-child {
			margin-bottom: calc(
				1.2 * var(--contact-line-height) * var(--contact-font-size)
			);
		}

		&:nth-child(2) {
			margin-bottom: calc(
				1 * var(--contact-line-height) * var(--contact-font-size)
			);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const Contact = (props) => {
	const { contact } = props;
	const cleanContact = useSanitizeHtml(contact, sanitizeDefaults);

	return <StyledContact dangerouslySetInnerHTML={{ __html: cleanContact }} />;
};

Contact.propTypes = {
	contact: PropTypes.string,
};

export default Contact;
