import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { randomBackground } from '../../util/util';

const StyledBackground = styled.div`
	--ellipse-offset: 0px;
	--height: calc(100% - var(--ellipse-offset));
	width: 100%;
	height: var(--height);
	position: absolute;
	top: 0;
	left: 0;
	background-image: ${({ background }) =>
		background ? `url('/assets/bg-${background}.svg')` : ``};
	background-repeat: repeat-y;
	z-index: -1;

	${({ background }) =>
		background === 'sawtooth' &&
		`
			@media (max-width: 30em) {
				background-position: -20px -20px;
				background-size: calc(365vw / 450 * 100) auto;
			}

			@media (min-width: 30em) and (max-width: 80em) {
				background-position: -9px -9px;
				background-size: calc(700vw / 1280 * 100) auto;
			}

			@media (min-width: 80em) {
				background-position: -1px -14px;
				background-size: 1080px auto;
			}
		`}
	${({ background }) =>
		background === 'star' &&
		`
			@media (max-width: 30em) {
				background-position: -12px -2px;
				background-size: calc(314vw / 450 * 100) auto;
			}

			@media (min-width: 30em) and (max-width: 80em) {
				background-position: 0px 25px;
				background-size: calc(835vw / 1280 * 100) auto;
			}

			@media (min-width: 80em) {
				background-position: -19px 26px;
				background-size: 1063px auto;
			} 
		`}
	${({ background }) =>
		background === 'ellipse' &&
		`
			@media (max-width: 30em) {
				background-position: -12px 0px;
				background-size: calc(217vw / 450 * 100) auto;
			}

			@media (min-width: 30em) {
				--ellipse-offset: 30px;
				margin-top: var(--ellipse-offset);
			}

			@media (min-width: 30em) and (max-width: 80em) {
				background-position: 0px 0px;
				background-size: calc(516vw / 1280 * 100) auto;
			}

			@media (min-width: 80em) {
				background-position: -5px 0px;
				background-size: 655px auto;
			} 
		`}
`;

const Background = () => {
	const [background, setBackground] = useState('');

	useEffect(
		() => setBackground(randomBackground(['ellipse', 'sawtooth', 'star'])),
		[]
	);

	return <StyledBackground background={background} />;
};

export default Background;
