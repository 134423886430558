import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledInfoButton = styled.button`
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	cursor: pointer;
	position: absolute;

	&:focus {
		outline: none; /* not my call */
	}

	@media (max-width: 29.9375em) {
		top: 26px;
		right: 31px;

		& svg {
			width: calc(42rem / 16);
			height: calc(42rem / 16);
		}
	}

	@media (min-width: 30em) and (max-width: 89.9375em) {
		top: 35px;
		right: 25px;

		& svg {
			width: calc(52rem / 16);
			height: calc(52rem / 16);
		}
	}

	@media (min-width: 90em) {
		top: 33px;
		right: 36px;

		& svg {
			width: calc(66rem / 16);
			height: calc(66rem / 16);
		}
	}
`;

const InfoButton = (props) => {
	const { info, showInfo, handleClick } = props;
	const boundHandleClick = (fn) => fn.bind(null, info, showInfo);

	return (
		<StyledInfoButton
			onClick={boundHandleClick(handleClick)}
			aria-label="close"
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox=" 0 0 99 99">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M98.957 8.285L90.713.042 49.499 41.258 8.283.042.042 8.285 41.257 49.5.042 90.716l8.241 8.241 41.216-41.215 41.214 41.215 8.244-8.241L57.741 49.5z"
				/>
			</svg>
		</StyledInfoButton>
	);
};

InfoButton.propTypes = {
	info: PropTypes.bool,
	showInfo: PropTypes.func,
	handleClick: PropTypes.func,
};

export default InfoButton;
