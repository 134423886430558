const transformUrl = (url, substring, transformation) => {
	const slicedString = [
		url.slice(0, url.indexOf(substring) + substring.length + 1),
		url.slice(url.indexOf(substring) + substring.length),
	];

	const transformedUrl = `${slicedString[0]}${transformation}${slicedString[1]}`;

	return transformedUrl;
};

const randomBackground = (backgroundArray) =>
	backgroundArray[Math.floor(Math.random() * backgroundArray.length)];

const sanitizeDefaults = {
	allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'sup', 'br'],
	allowedAttributes: {
		a: ['href', 'target', 'rel', 'title', 'class'],
	},
};

export { transformUrl, randomBackground, sanitizeDefaults };
