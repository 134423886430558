import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSanitizeHtml from '../../util/use-sanitize-html';
import { sanitizeDefaults } from '../../util/util';

const StyledCopyright = styled.div`
	margin-bottom: auto;

	@media (max-width: 29.9375em) {
		font-size: 1rem;
	}

	@media (min-width: 30em) and (max-width: 89.9375em) {
		font-size: calc(18rem / 16);
	}

	@media (min-width: 90em) {
		font-size: calc(23rem / 16);
	}

	p {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const Copyright = (props) => {
	const { copyright } = props;
	const cleanCopyright = useSanitizeHtml(copyright, sanitizeDefaults);

	return (
		<StyledCopyright dangerouslySetInnerHTML={{ __html: cleanCopyright }} />
	);
};

Copyright.propTypes = {
	copyright: PropTypes.string,
};

export default Copyright;
