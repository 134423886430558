import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMenuButton = styled.button`
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	cursor: pointer;
	transform-origin: 100% 0%;
	line-height: 1;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	&:active {
		color: black;
	}

	&:focus {
		outline: none; /* not my call */
	}

	@media (max-width: 47.9375em) {
		transform: rotate(-90deg) translate(20px, calc(-100% + 3px));
		position: absolute;
		right: 0;
	}

	@media (min-width: 48em) {
		transform: rotate(-90deg) translate(-6px, calc(-100% + 5px));
	}
`;

const MenuButton = (props) => {
	const { showInfo, info, handleClick, children } = props;
	const boundHandleClick = (fn) => fn.bind(null, info, showInfo);

	return (
		<StyledMenuButton onClick={boundHandleClick(handleClick)}>
			{children}
		</StyledMenuButton>
	);
};

MenuButton.propTypes = {
	info: PropTypes.bool,
	showInfo: PropTypes.func,
	handleClick: PropTypes.func,
	children: PropTypes.string,
};

export default MenuButton;
